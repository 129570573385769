.pancake-config {
  min-height: 100vh;
  padding: 15px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pancake-config .data-source {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.pancake-config .source-config,
.pancake-config .cosultant-config {
  min-height: 800px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 10px 15px;
  border-radius: 8px;
}

.pancake-config .config-item {
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.pancake-config .config-item .config-page {
  min-width: 400px;
}

.light .pancake-config {
  background-color: #fff;
}

.dark .pancake-config {
  background-color: #1d1d1d;
}

@media (max-width: 768px) {
  .pancake-config .data-source {
    grid-template-columns: repeat(2, 1fr);
  }

  .pancake-config .config-item .config-page {
    min-width: 100%;
  }
}

@media (max-width: 476px) {
  .pancake-config .data-source {
    grid-template-columns: repeat(1, 1fr);
  }
}
