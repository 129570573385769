.ranks {
  background: #fff;
  min-height: 100vh;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dark .ranks {
  background-color: #0e1111;
}
