.noti-item {
  width: 100%;
  max-width: 1100px;
  margin: 20px auto;
  padding: 10px 15px;
  border-radius: 8px;
}

.noti-item .des {
  color: #8f8f8f;
  transform: translateY(-8px);
}

.noti-item .content {
  margin-top: 15px;
}

.noti-item .comments,
.noti-item .comments-form {
  margin-top: 20px;
}

.noti-item .list-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noti-reacts {
  margin-top: 20px;
}

.noti-reacts .emoji {
  display: flex;
  align-items: center;
}

.noti-reacts .emoji > * {
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.noti-reacts .emoji img {
  margin-left: 5px;
}

.light .noti-item {
  background-color: #fff !important;
}

.dark .noti-item {
  background-color: #0e1111 !important;
}
