.login {
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  margin: 20px auto;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.login h1 {
  text-align: center;
}

.login .banner {
  /* background-color: rgb(170, 200, 255); */
  margin-bottom: 25px;
}

.login .banner-text {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
