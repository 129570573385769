/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;

  transform: translateZ(0);
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: rgba(186, 186, 192, 0.7);
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

.inline-input-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.inline-input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.hide {
  display: none !important;
}

.flex {
  display: flex;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

.mb-0 {
  margin-bottom: 0 !important;
}

html,
.two-cols-section {
  width: 100vw !important;
  max-width: 100% !important;
}

body {
  overflow-x: hidden;
  /* font-family: 'Public Sans', sans-serif !important; */
}

.app {
  width: 100vw !important;
  max-width: 100% !important;
  min-height: 100vh !important;
}

.two-cols-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.main-content {
  max-width: 100%;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}

.menu-left {
  min-height: 100vh;
  margin-top: 64px;
  width: 100%;
}

.footer {
  margin: 20px auto;
}

@media (max-width: 900px) {
  .sider-wrapper {
    display: none;
  }
  .content-wrapper {
    margin-left: 0 !important;
  }
}

/* grid */

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 8px; */
}

.justify-between {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-5 {
  flex: 1 0 calc(20% - 16px);
  margin: 8px;
  min-width: 200px;
  max-width: 300px;
}

@media (max-width: 476px) {
  .col-5 {
    max-width: 100%;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.btn {
  margin: 50px auto;
  border-radius: 25px;
  background-color: #5300e8;
  box-shadow: 0 2px 4px 0 #888888;
  display: inline-block;
  padding: 15px 50px;
  color: #ffffff;
}

.ant-btn {
  overflow: hidden !important;
  position: relative !important;
  cursor: pointer !important;
}

.ant-btn-primary:hover {
  box-shadow: 0 8px 25px -8px rgb(255 205 15 / 0.3) !important;
}

.ant-btn-primary::after {
  display: block;
  content: "";
  position: absolute;
  inset: 0;
  filter: blur(12px) !important;
}

.ant-layout-footer {
  background-color: transparent !important;
}

.light .ant-menu-submenu:hover .ant-menu-submenu-arrow {
  color: rgba(28, 29, 33, 0.88) !important;
}

.ant-tag {
  border-radius: 8px !important;
  padding: 4px 8px !important;
}

.ant-badge-status-success {
  box-shadow: 0 0 3px rgb(40 199 111 / 0.16) !important;
}

.ant-select-selector,
.ant-input-affix-wrapper {
  border-radius: 8px !important;
}

/* .ant-descriptions-item-content {
  background-color: #fff !important;
} */

.ant-popover-inner-content {
  max-width: 500px !important;
}

.ant-popover-inner-content > span {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.ant-avatar-group .ant-avatar {
  transition: all 0.2s ease-in-out;
}

.ant-avatar-group .ant-avatar:hover {
  transform: translateY(-5px);
}

.ant-layout-sider-trigger {
  left: 0 !important;
}

button.swal2-confirm {
  border-radius: 999px !important;
}

.swal2-html-container,
.swal2-popup .swal2-title,
.swal2-popup .swal2-image {
  margin: 0 !important;
}

.swal2-image {
  object-fit: contain !important;
  width: 100% !important;
}

/* ripple effect */
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple .rippleContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ripple .rippleContainer span {
  transform: scale(0);
  border-radius: 100%;
  position: absolute;
  opacity: 0.75;
  background-color: #ffffff;
  animation: ripple 850ms;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}

/* shine effect */
.shine {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.shine:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shine:hover:after {
  opacity: 1;
  left: 130%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}

.card-cover-container {
  overflow: hidden;
  height: 160px;
}

@media (min-width: 400px) {
  .card-cover-container {
    height: 200px;
  }
}

@media (min-width: 480px) {
  .card-cover-container {
    height: 230px;
  }
}

@media (min-width: 540px) {
  .card-cover-container {
    height: 250px;
  }
}

@media (min-width: 576px) {
  .card-cover-container {
    height: 130px;
  }
}

@media (min-width: 615px) {
  .card-cover-container {
    height: 160px;
  }
}

@media (min-width: 768px) {
  .card-cover-container {
    height: 120px;
  }
}

@media (min-width: 900px) {
  .card-cover-container {
    height: 80px;
  }
}

@media (min-width: 1200px) {
  .card-cover-container {
    height: 110px;
  }
}

@media (min-width: 1370px) {
  .card-cover-container {
    height: 130px;
  }
}

@media (max-width: 475px) {
  .inline-input.flex-col {
    display: flex;
    flex-direction: column;
  }
  .ant-select {
    width: 100%;
  }
  .ant-cascader-dropdown {
    width: 100vw;
  }
}

@media (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dark .wrapper {
  background: #0e1111;
}

.light .wrapper {
  /* background: #e8e9ee; */
  background-color: transparent;
}

.light .sider-wrapper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dark .ant-table-expanded-row-fixed {
  background-color: #141414 !important;
}

.dark .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}

.dark .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #141414;
}

.light .content-wrapper {
  background-color: #fff !important;
}

.blink-1 {
  animation: blink-1 1s infinite;
}

@keyframes blink-1 {
  0%,
  100% {
    background-color: #ffcc00; /* Màu ban đầu */
  }
  50% {
    background-color: #ff6699; /* Màu nhấp nháy */
  }
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.ref-content-1 {
  transform: scale(0.4) translateY(-600px);
}

.ref-content-2 {
  transform: scale(0.4) translateY(-1800px);
}

.ref-content-3 {
  transform: scale(0.4) translateY(-3000px);
}

@media (min-width: 992px) {
  .ref-content {
    transform: scale(0.8) translateY(0);
  }
}

@media (min-width: 1280px) {
  .ref-content {
    transform: scale(1) translateY(0);
  }
}

.ant-modal-mask {
  backdrop-filter: blur(2px);
}
