.mn-revenue {
  border-radius: 8px;
}

.lower {
  color: rgb(252, 42, 42);
}

.equal {
  color: dodgerblue;
}

.greater {
  color: rgb(1, 211, 1);
}
