.mn-requests {
  padding: 10px 15px;
  margin: 0 !important;
  min-height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

.light .mn-requests {
  background: #fff;
}

.dark .mn-requests {
  background: #0e1111;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffcd0f !important;
}
