.ranking-skeleton {
  width: 100%;
  height: 100%;
  min-height: 600px;
  margin-top: 20px;
}

.ranking-wrapper {
  display: flex;
  flex-direction: column;
}

.ranking-table-wrapper .ant-table-cell {
  border: 0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 12px 10px !important;
}

.ranking-table-wrapper th.ant-table-cell::before {
  display: none;
}

.ranking-info {
  background: #fff;
  height: auto;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ranking-filter {
  background-color: #fff;
  border-radius: 8px;
  padding: 5px 15px 15px;
  max-width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ranking-filter-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ranking-filter-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 10px;
}

.ranking-filter-item b {
  margin-right: 10px;
}

.ranking-note {
  margin-top: 10px;
}

.ranking-top-block {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
}

.ranking-bottom-block {
  overflow-x: scroll;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.ranking-info,
.ranking-table-wrapper,
.ranking-statistics {
  padding: 10px 15px;
  border-radius: 8px;
}

.ranking-statistics {
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #fff;
}

.ranking-statistics canvas {
  width: 100% !important;
  height: 150px !important;
}

.ranking-table-wrapper:hover,
.ranking-info:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ranking-table table {
  border: 0 !important;
  margin-top: 20px;
}

.ranking-table thead tr th {
  background-color: #fafafa !important;
  color: #868692 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

.rank {
  font-weight: 600;
}

.no {
  font-size: 18px;
  font-weight: 600;
}

.ranking-explain-table {
  border-collapse: collapse;
  margin: 20px auto;
}

.ranking-explain-table th,
.ranking-explain-table td {
  border: 1px solid #000;
  text-align: center;
  padding: 8px;
}
.ranking-explain-table th {
  background-color: #4caf50;
  color: white;
}

@media (max-width: 1635px) {
  .ranking-top-block {
    grid-template-columns: 2fr 1fr;
  }
}

@media (max-width: 1400px) {
  .ranking-top-block {
    grid-template-columns: 1.5fr 1fr;
  }
}

@media (max-width: 1100px) {
  .ranking-top-block {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .no {
    font-size: 15px;
    font-weight: 600;
  }
}

.light .ranking-item-even {
  background-color: #f3f3f3;
}

.dark .percent-sign.zero {
  color: #fff;
}

.rank-top {
  font-weight: 600 !important;
  color: #fff;

  animation-name: rainbow;
  -webkit-animation-name: rainbow;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.ranking-table .ant-avatar {
  border: 2px solid #ffcd0f;
  border-radius: 999px;
}

.rank-second {
  color: dodgerblue;
}

.ranking-progress {
  margin-top: 10px;
}

.ranking-progress p {
  margin: 0;
}

.ranking-info .boxes {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.ranking-info .boxes .box {
  height: auto;
  min-height: 80px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transition: all 0.2s ease-in-out;
}

.light .ranking-info .boxes .box {
  background-color: #fff;
}

.dark .ranking-info .boxes .box {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
}

.ranking-info .boxes .box:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ranking-info .boxes .box .box-title {
  margin-bottom: 6px !important;
  font-weight: 500;
  color: #818897;
}

.ranking-info .boxes .box .box-num {
  font-size: 18px;
  font-weight: 500;
}

.ranking-info .boxes .box .box-title .anticon {
  padding: 5px;
  border-radius: 50%;
}

.ranking-info .boxes .box-recieved .anticon {
  background-color: #ffe9e9;
  color: #f2383a;
}

.ranking-info .boxes .box-students .anticon {
  background-color: #dff3ff;
  color: #09a1f7;
}

.ranking-info .boxes .box-discount .anticon {
  background-color: #f0ecfe;
  color: #8c74e8;
}

.ranking-info .boxes .box-unpaid .anticon {
  background-color: #e3fff7;
  color: #55efc4;
}

.ranking-info .note p {
  margin-bottom: 5px !important;
}

@keyframes rainbow {
  0% {
    color: red;
  }
  17% {
    color: #ff0;
  }
  33% {
    color: #0f0;
  }
  50% {
    color: #0ff;
  }
  67% {
    color: #00f;
  }
  84% {
    color: #f0f;
  }
  100% {
    color: red;
  }
}

.light .ranking-table-wrapper,
.light .ranking-info {
  background-color: #fff;
}

.dark .ranking-table-wrapper,
.dark .ranking-info {
  background-color: #0e1111;
}
