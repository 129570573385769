.thalic-watermark {
  position: relative;
  overflow: hidden;
}

.thalic-watermark-overlay {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: repeat;
  opacity: 0.25;
  transform: rotate(-30deg);
}
