.file-import__table .ant-table-cell {
  border: 0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  text-align: center !important;
  min-width: 200px;
}

.file-import__table th.ant-table-cell::before {
  display: none;
}

.file-import__table table {
  border: 0 !important;
}

.file-import__table thead tr th {
  background-color: #fafafa !important;
  color: #868692 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}
