.request-item {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.request-item .btn-group {
  display: flex;
  align-items: center;
}

.request-item .btn-request {
  padding: 3px 8px;
  margin-left: 15px;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
}

.rq-content {
  color: #000;
}

.request-item .btn-request:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.request-item .accept-btn {
  background-color: #2ecc71;
}

.request-item .reject-btn {
  background-color: #e74c3c;
}

.light .delete-icon {
  background-color: #fff;
}

.dark .delete-icon {
  background-color: transparent;
}
