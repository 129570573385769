.rq-sent {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rq-content {
  color: #000;
}
