.light .ant-menu {
  font-weight: 600;
  color: rgb(107 114 128 / 1) !important;
  background-color: #fff;
  user-select: none !important;
}

.light .ant-menu-item {
  overflow: visible !important;
}

.light
  .ant-menu-submenu
  .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected)
  a,
.light
  .ant-menu-submenu
  .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected)
  .anticon {
  font-weight: 400;
  color: rgb(47 43 61 / 0.9) !important;
}

.light .ant-menu-item,
.light .ant-menu-submenu,
.light .ant-menu-submenu-title {
  border-radius: 8px;
  transition: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light .ant-menu-item a,
.light .ant-menu-item .anticon,
.light .ant-menu-submenu .ant-menu-title-content,
.light .ant-menu-submenu .anticon {
  color: rgba(28, 29, 33, 0.88) !important;
}

.light .ant-menu-item:hover,
.light .ant-menu-item-active,
.light .ant-menu-submenu-title:hover {
  background-color: rgba(28, 29, 33, 0.06) !important;
  color: rgba(28, 29, 33, 0.88) !important;
}

.light .ant-menu-submenu:hover > .ant-menu-submenu-title {
  background-color: rgba(28, 29, 33, 0.06) !important;
}

.light .ant-menu-item-selected,
.light .ant-menu-item-selected:hover {
  /* background-color: rgb(239 242 254) !important; */
  box-shadow: 0 8px 25px -8px rgb(255 205 15 / 0.3) !important;
  background: linear-gradient(
    270deg,
    rgb(255 205 15 / 0.7) 0%,
    #ffcd0f 100%
  ) !important;
}

/* 
.ant-menu-item-selected .anticon,
.ant-menu-item::after {
  color: #5e81f4 !important;
  border-color: #5e81f4 !important;
} */

.light .ant-menu-item::after {
  /* border-color: rgb(255 205 15 / 0.7) !important; */
  border-color: transparent !important;
}

.light .ant-menu-item::after {
  right: -9px !important;
}
