.task {
  width: 100%;
  padding: 10px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.task:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.task .title {
  display: flex;
  align-items: center;
}

.task .title span {
  margin-left: 5px;
}

.task .title .reload-btn {
  margin-left: 10px;
  cursor: pointer;
}

.task .status {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
}

.task .status .ant-form-item-label label {
  font-size: 12px !important;
}

.task .status .ant-select-selection-item {
  font-size: 12px !important;
}

.task-empty {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.task-empty-img {
  max-width: 50%;
  margin-bottom: 10px;
}
