.header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 15px 5px;
}

.ant-layout-header,
.header-v2 {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.light .ant-layout-header,
.header-v2 {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  /* box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.08); */
  border-bottom: 1px solid rgb(221 221 221);
}

.dark .ant-layout-header {
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  box-shadow: rgba(2, 1, 1, 0.1) 0px 5px 20px -5px;
  border-bottom: 1px solid rgb(29, 29, 29);
}

.ant-menu-submenu-title,
.ant-menu-submenu-title a {
  color: #ffcd0f !important;
  font-weight: 600 !important;
}

.header .user-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ant-layout-header .user-box .user-menu {
  display: flex;
  align-items: center;
}

.ant-layout-header .user-box .hello {
  display: flex;
  align-items: center;
  margin: auto 10px;
  font-weight: 500;
}

.ant-layout-header .user-box .hello .user_name {
  margin-left: 3px;
  font-weight: 500;
}

.ant-layout-header .menu-items {
  width: 60%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.noti-item-text {
  cursor: pointer;
  max-width: 250px;
  margin: 0 0 0 5px !important;
}

.noti-menu-item {
  display: flex;
  align-items: flex-start;
  padding: 5px;
  border-radius: 8px;
  width: auto;
  min-width: 300px;
}

.noti-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.noti-sub-text {
  color: #7f8c8d !important;
  font-size: 13px;
}

.logo {
  font-size: 13px;
  color: #ffcd0f;
  font-weight: 500;
  position: relative;
}

.logo .text {
  position: absolute;
  top: 18px;
  right: -34px;
  font-size: 14px;
}

.logout {
  border: 1px solid black;
  border-radius: 8px;
  padding: 3px 5px;
  cursor: pointer;
}

.user-mobile-menu {
  display: none;
}

.light .ant-menu,
.dark .ant-menu {
  box-shadow: unset !important;
  border: 0 !important;
  padding-bottom: 100px;
}

.ant-layout-sider-trigger {
  height: 35px !important;
  line-height: 35px !important;
}

.light .ant-layout-sider-trigger {
  background-color: #fff !important;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
}

.light .ant-layout-sider-trigger span {
  color: #000 !important;
}

.dark .ant-layout-sider-trigger {
  background-color: #1e1e1e !important;
  box-shadow: 0 -5px 10px -5px rgba(255, 255, 255, 0.2);
}

.dark .ant-layout-sider-trigger span {
  color: #fff !important;
}

.ant-menu-item {
  display: flex !important;
  align-items: center !important;
}

.ant-layout-header .noti {
  margin-right: 20px;
}

.ant-layout-header .noti .noti-icon {
  font-size: 20px;
  margin-bottom: -5px;
}

.headerv2 {
  width: 256px;
  min-height: calc(100vh + 3px);
}

@media (max-width: 900px) {
  .ant-layout-header .menu-items,
  .user-menu {
    display: none !important;
  }
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
  }
  .user-mobile-menu {
    display: block;
  }
  .user_name {
    color: #ffcd0f;
  }
  .ant-layout-header {
    padding: 0 10px 0 15px !important;
  }
  .ant-layout-header .user-box {
    right: 0;
  }
  .ant-layout-header .user-box .ant-btn {
    padding: 4px 0 !important;
  }
  .ant-layout-header .noti {
    margin-right: 15px;
  }
}
