.input-table {
  margin: 0 auto;
  width: 100vw !important;
  max-width: 100% !important;
  white-space: nowrap;
  min-height: calc(100vh - 150px);
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 15px;
}

.input-table .table-header {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.input-table .table-header .left {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.input-table .left > div,
.input-table .right {
  margin-bottom: 10px;
}

.delete-btn:active,
.delete-btn:focus,
.delete-btn:hover {
  color: #fff !important;
  border-color: #c0392b !important;
  background-color: #e74c3c !important;
}

.add-btn {
  margin-left: 10px !important;
  color: #fff !important;
  border-color: #2d3436 !important;
  background-color: #636e72 !important;
  margin-right: 10px !important;
}

.add-btn:hover {
  background-color: #2d3436 !important;
}

.ant-modal {
  top: 50px !important;
}

.modal-bill,
.modal-bill .ant-modal-content {
  width: 100% !important;
  height: calc(100vh - 16px);
  overflow: hidden;
  top: 0 !important;
}

.modal-bill .ant-modal-body {
  height: 100% !important;
}

.modal-invoice,
.modal-invoice .ant-modal-content {
  width: 100% !important;
}

.ant-table-cell {
  width: auto !important;
  border-left: 0 !important;
  border: 1px solid black !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ant-table table {
  border: 1px solid black !important;
}

.ant-table thead tr th {
  height: 64px !important;
  background-color: rgb(255 205 15 / 1) !important;
  color: #000;
  text-align: center !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  max-width: 300px !important;
  border-bottom: 0 !important;
}

.ant-table-header:not(.ant-modal-body .ant-table-header) {
  top: 64px !important;
}

.statistics-table .ant-table-cell {
  white-space: nowrap !important;
}

.nested-table thead tr th {
  background-color: initial !important;
  border-top: 0 !important;
}

.nested-table .ant-table-cell {
  background-color: initial !important;
}

.nested-table table {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.nested-table .ant-table-container {
  transform: translateX(-9px) !important;
}

@media (min-width: 600px) {
  .statistics-table .ant-table-cell {
    font-size: 12px !important;
  }
}

.fee-text {
  color: #ff9f1a;
}

.source-zone,
.filter-zone {
  display: flex;
  align-items: center;
  position: relative;
}

.source-zone {
  margin-left: 10px;
}

.source-zone > div {
  margin-right: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ffcd0f;
}

.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #434343 !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ad8c11;
}

.ant-select:not(.ant-select-disabled):active .ant-select-selector {
  border-color: #ffcd0f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f !important;
}

.light .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.light .input-table {
  background-color: #fff;
}

.dark .input-table {
  background-color: transparent;
}

.ant-typography.ant-typography-danger {
  color: #ff4d4f !important;
  font-weight: 500 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td:not(.ant-table-cell-fix-left) {
  background: none !important;
}

/* .light .data-from-web {
  background-color: #ffeaa7;
}

.dark .data-from-web {
  background-color: #ffeaa785;
}

.light .data-from-pancake {
  background-color: rgba(80, 109, 173, 0.2);
}

.dark .data-from-pancake {
  background-color: rgba(80, 109, 173, 0.2);
} */

.light .data-warning {
  background-color: #ffa6a6;
}

.dark .data-warning {
  background-color: #ffa6a6;
}

.light .data-callback {
  background-color: #ffeaa7;
}

.dark .data-callback {
  background-color: #ffeaa785;
}

.data-registered {
  background-color: #b10202;
}

.data-registered td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* L1 */
.data-l1 {
  background-color: #e8eaed;
}

.data-l1 td:not(.ant-table-cell-fix-left) {
  color: #000000 !important;
}

/* L2 */
.data-l2 {
  background-color: #ffcfc9;
}

.data-l2 td:not(.ant-table-cell-fix-left) {
  color: #b10202 !important;
}

/* L3 */
.data-l3 {
  background-color: #e6cff2;
}

.data-l3 td:not(.ant-table-cell-fix-left) {
  color: #5a3286 !important;
}

/* L4 */
.data-l4 {
  background-color: #ddebff;
}

.data-l4 td:not(.ant-table-cell-fix-left) {
  color: #2a3243 !important;
}

/* L5 */
.data-l5 {
  background-color: #11734b;
}

.data-l5 td:not(.ant-table-cell-fix-left) {
  color: #d4edbc !important;
}

/* L8 */
.data-l8 {
  background-color: #ff4646;
}

.data-l8 td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* L9 */
.data-l9 {
  background-color: #753800;
}

.data-l9 td:not(.ant-table-cell-fix-left) {
  color: #ffc8aa !important;
}

/* L11 */
.data-l11 {
  background-color: #0a53a8;
}

.data-l11 td:not(.ant-table-cell-fix-left) {
  color: #bfe0f6 !important;
}

/* L12 */
.data-l12 {
  background-color: #ffe5a0;
}

.data-l12 td:not(.ant-table-cell-fix-left) {
  color: #473821 !important;
}

/* **************** */

/* Đang học */
.data-dang-hoc {
  background-color: #e6cff2;
}

.data-dang-hoc td:not(.ant-table-cell-fix-left) {
  color: #5a3286 !important;
}

/* Bảo lưu */
.data-bao-luu {
  background-color: #d4edbc;
}

.data-bao-luu td:not(.ant-table-cell-fix-left) {
  color: #11734b !important;
}

/* Đã kết thúc */
.data-da-ket-thuc {
  background-color: #0a53a8;
}

.data-da-ket-thuc td:not(.ant-table-cell-fix-left) {
  color: #bfe0f6 !important;
}

/* Bỏ học */
.data-bo-hoc {
  background-color: #ff4646;
}

.data-bo-hoc td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* Chưa học */
.data-chua-hoc {
  background-color: #ff4646;
}

.data-chua-hoc td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* Khách hoàn thành 100% học phí */
.data-hoan-thanh-hoc-phi {
  background-color: #ff4646;
}

.data-hoan-thanh-hoc-phi td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* Đóng học phí nhưng chưa khai giảng */
.data-hoan-thanh-hoc-phi-chua-kg {
  background-color: #ff4646;
}

.data-hoan-thanh-hoc-phi-chua-kg td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* Khách combo nhưng chưa KG */
.data-combo-chua-kg {
  background-color: #ff4646;
}

.data-combo-chua-kg td:not(.ant-table-cell-fix-left) {
  color: #ffcfc9 !important;
}

/* Đã khai giảng */
.data-da-kg {
  background-color: #753800;
}

.data-da-kg td:not(.ant-table-cell-fix-left) {
  color: #ffc8aa !important;
}

/* trước ngày KG 3 ngày */
.data-truoc-kg-3d {
  animation: blink-1 1s infinite;
}

/* trước liên hệ tiếp theo 30 phút */
.data-next-contact-30m {
}
