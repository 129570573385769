.source-item {
  max-width: 100%;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  transition: all 0.2s ease-in-out;
}

.source-item:hover {
  background-color: rgba(109, 109, 109, 0.05);
}

.source-item-info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex: 1;
}

.source-item-info .source-name {
  font-weight: 500;
}

.source-item-info .source-username,
.source-item-info .source-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
