.bill-pdf {
  text-align: center;
  padding: 20px 15px;
  font-size: 26px;
}

.bill-pdf .note {
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  text-align: left;
}

.bill-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.bill-heading {
  font-size: 22px;
}

.bill-details-lg .bill-heading {
  font-size: 26px;
}

.bill-details-lg .bill-subheading {
  font-size: 20px;
}

.bill-header p {
  margin: 0;
  margin-left: 6px;
  font-size: 15px;
  margin-bottom: -5px;
}

.bill-subheading {
  margin-top: -10px;
  font-size: 18px;
}

.bill-details-lg .bill-subheading,
.bill-details-lg .bill-header p {
  font-size: 18px !important;
}

.bill-details-lg .ant-descriptions-item-label,
.bill-details-lg .ant-descriptions-item-content {
  font-size: 22px !important;
}
