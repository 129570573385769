.light {
  @import '~antd/lib/style/themes/default.less';
  @import '~antd/dist/antd.less';

  @primary-color: #ffcd0f;

  @input-hover-border-color: #ffcd0f;
  @input-icon-hover-color: #ffcd0f;

  @component-background: #fff;
  @body-background: transparent;
  // @layout-body-background: #fff;
  @layout-header-background: #fff;

  @list-item-padding: 10px 5px;

  @border-radius-base: 8px;

  @text-color: rgb(47 43 61 / 0.9);
  @text-color-secondary: rgb(47 43 61 / 0.7);
}
