.setting-user {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  padding: 15px;
}

.table-header-add-class {
  display: flex;
  align-items: center;
}

.table-header-add-class > span {
  margin-left: 5px;
  margin-bottom: -3px;
  font-style: italic;
}

.ant-modal.class-setting {
  width: 1000px !important;
}

.light .username {
  color: #1c1c1c;
}
