.mn-requests {
  padding: 10px 15px;
  margin: 0 15px;
  min-height: 100vh;
}

.light .mn-requests {
  background: #fff;
}

.dark .mn-requests {
  background: #0e1111;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffcd0f !important;
}
