.sub-class-list {
  padding: 0 35px 20px;
  margin: 0;
  width: 100%;
  min-height: calc(100vh - 290px);
}

.sub-class-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-class-list .ant-list-item {
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 5px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: all 0.2s ease-in-out;
}

.sub-class-list .ant-list-item:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.sub-class-list .ant-list-item-action li {
  color: #3d3d3d !important;
  height: 28px;
}

.dark .sub-class-list .ant-list-item {
  background-color: #1d1d1d;
  border: 0;
}

.sub-class-list .ant-list-split .ant-list-header {
  border-bottom: 0;
}

.sub-class-list .class-name {
  font-size: 18px;
  font-weight: bold;
  /* color: #ffcd0f; */
  margin: 0;
  margin-bottom: 8px;
}

.ant-list-item {
  padding: 15px 15px 15px 35px !important;
}

.sub-class-list .sub-class-text {
  margin-left: 20px;
}

.sub-class-list .sub-class-details {
  margin-top: 5px;
}

.sub-class-list .sub-class-details .icon-text {
  margin-right: 10px;
}

@media (max-width: 623px) {
  .sub-class-list {
    padding: 0 0 20px;
  }
}

@media (max-width: 476px) {
  .sub-class-list .sub-class-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .sub-class-avt {
    display: none;
  }
  .sub-class-text {
    margin-left: 0 !important;
  }

  .sub-class-list .ant-list-item {
    padding: 10px 20px !important;
  }
}

.dark .sub-class-list {
  background-color: transparent !important;
}

.light .sub-class-list {
  background-color: transparent !important;
}

.light .ant-list-item {
  background-color: #fff !important;
}
