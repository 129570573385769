.notifications {
  height: auto;
  width: 100%;
  min-height: 600px;
  padding: 10px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

.notifications:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.noti-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  color: #ffcd0f !important;
}

.noti-body .list-des,
.noti-body .more-info {
  display: flex;
  align-items: flex-start;
}

.noti-body .list-des p {
  margin-right: 5px;
  margin-left: 5px;
}

.noti-body .ant-list-item {
  margin-bottom: 8px;
  transition: all 0.2s ease-in-out;
}

.noti-body .ant-list-item:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.noti-empty {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noti-empty-img {
  width: 30%;
  max-width: 100px;
  margin-bottom: 10px;
}

.light .notifications {
  background-color: #fff;
}

.dark .notifications {
  background-color: #0e1111;
}
