.random-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 4px 8px;
  cursor: pointer;
}

.random-color-box {
  width: 30px;
  height: 15px;
  background-color: #fff;

  animation-name: rainbow1;
  -webkit-animation-name: rainbow1;
  animation-duration: 6s;
  -webkit-animation-duration: 6s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes rainbow1 {
  0% {
    background-color: red;
  }
  17% {
    background-color: #ff0;
  }
  33% {
    background-color: #0f0;
  }
  50% {
    background-color: #0ff;
  }
  67% {
    background-color: #00f;
  }
  84% {
    background-color: #f0f;
  }
  100% {
    background-color: red;
  }
}
