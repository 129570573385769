.user-details__header {
  margin-bottom: 20px;
}

.user-details {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.user-details-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-details-heading p {
  font-weight: 500;
}
