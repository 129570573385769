.extras {
  padding: 20px 15px;
  min-height: calc(100vh - 120px);
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.light .extras {
  background-color: #fff;
}
