.loader {
  background-color: transparent;
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  border-radius: 8px;
}

.dark .loader {
  background-color: rgb(55, 55, 55);
}

.loader.loader-full {
  min-height: calc(100vh - 150px);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 
.loader img {
  animation: loaderAnimation 2s infinite;
} */

@keyframes loaderAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
