.top-notifications-container {
  width: 100%;
  height: auto;
}

.top-notifications-container .noti-content {
  max-width: calc(100vw - 100px);
  font-weight: 600;
  font-size: 14px;
}

.active-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.light .active-tab {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* border: 1px solid rgb(47 43 61 / 0.12);
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
}

.dark .active-tab {
  background-color: #1d1d1d;
  color: #fff;
}
