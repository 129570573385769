.table-header-add-class {
    display: flex;
    align-items: center;
}

.table-header-add-class>span {
    margin-left: 5px;
    margin-bottom: -3px;
    font-style: italic;
}

.ant-modal.class-setting {
    width: 1000px !important;
}