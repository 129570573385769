.spinner {
    padding: 10px 15px;
    margin: 10px 15px;
    width: auto;
    height: calc(100vh - 150px);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark .spinner {
    background-color: #0e1111 !important;
}