.user-details-item {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 10px;
  padding-top: 10px;
}

.user-details-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
}
