.ant-card-cover img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.ant-card-bordered {
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ant-card-bordered:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.class-list .ant-card-head-title {
  padding: 0 !important;
  padding-top: 5px !important;
}

.title-card {
  margin: 0 !important;
}

.class-list .ant-card-body {
  padding: 5px 15px !important;
}

@media (max-width: 500px) {
  .title-card {
    font-size: 15px;
  }
}

@media (max-width: 500px) {
  .title-card {
    font-size: 15px;
  }
}

.class-list {
  background: transparent;
  min-height: calc(100vh - 150px);
}

.class-list .card-cover-container {
  /* background-image: linear-gradient(135deg, #ffa8a8 10%, #fcff00 100%); */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.class-list .ant-tag {
  border-radius: 999px !important;
  border: 0;
  font-weight: 600;
}
