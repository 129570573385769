.statistics {
  max-width: 100%;
}

.statistics-header {
  padding: 10px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.light .statistics-header {
  border-bottom: 1px solid #e2e2e2;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dark .statistics-header {
  background-color: #1d1d1d;
}

.statistics-header__main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.statistics-header__main .ant-select {
  margin-bottom: 10px;
}

.heading-chart {
  font-size: 20px;
  font-weight: 500;
}

.chart-section {
  padding: 10px 15px 50px;
  border-radius: 8px;
}

.light .chart-section {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}

.dark .chart-section {
  background-color: #1d1d1d;
}
