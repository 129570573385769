.system-config {
  padding: 20px 15px;
  min-height: 100vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
}

.light .system-config {
  background-color: #fff;
}
